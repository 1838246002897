import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import { BoldTypography, BoldTypographyLarge } from './common/TextLabel';
import Moment from 'react-moment';
import Table from '@mui/material/Table';
import { decode } from 'he';
import { capitalise } from '../utils/common';
import plusButton from '../icons/plus.png';
import removeButton from '../icons/remove.png';
import { ReactComponent as PencilIcon } from '../icons/pencil.svg';
import { Grid } from '@mui/material';

export const PlaylistTable = ({
  videos,
  onSetVideoPreview,
  onSetAddVideoPreview,
  onSetEditVideoPreview,
  onRemoveVideo,
  format,
}) => {
  const nowTimestamp = Math.floor(new Date().getTime() / 1000);
  const isPublisherView = localStorage.getItem('isPublisherView') === 'true';
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>VIDEO</TableCellHead>
          <TableCellHead>TITLE</TableCellHead>
          <TableCellHead>RIGHTSHOLDER</TableCellHead>
          <TableCellHead>DATE</TableCellHead>
          <TableCellHead>ID</TableCellHead>
          <TableCellHead>DURATION</TableCellHead>
          {!isPublisherView && <TableCellHead width="160">TOOLS</TableCellHead>}
        </TableRow>
      </TableHead>
      <TableBody>
        {videos.length === 0 && (
          <TableRow>
            <TableCellBody colSpan={6} width={'100%'}>
              <div style={{ textAlign: 'center', padding: 20 }}>This playlist contains no videos yet</div>
            </TableCellBody>
            {!isPublisherView && (
              <TableCellBody sx={{ minWidth: '200px' }}>
                <span onClick={() => onSetAddVideoPreview({ pos: nowTimestamp })} style={{ cursor: 'pointer' }}>
                  <img width="28" src={plusButton} alt="plus button" />
                </span>
              </TableCellBody>
            )}
          </TableRow>
        )}
        {videos &&
          videos.map(row => (
            <TableRow
              sx={{
                height: '60px',
              }}
              key={row.id}
            >
              <TableCellBody
                sx={{ width: '104px' }}
                onClick={() => {
                  onSetVideoPreview(row);
                }}
              >
                {format === 'vertical' && (
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '128px',
                      borderRadius: '5%',
                    }}
                  ></div>
                )}
                {format === 'horizontal' && (
                  <div
                    style={{
                      backgroundImage: `url(${row.thumbnail || '/bg-grey.png'})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '72px',
                      borderRadius: '5%',
                    }}
                  ></div>
                )}
              </TableCellBody>
              <TableCellBody sx={{ minWidth: '287px' }}>{decode(row.title)}</TableCellBody>
              <TableCellBody sx={{ minWidth: '150px' }}>
                <BoldTypographyLarge>{capitalise(row.rightsholder)}</BoldTypographyLarge>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>
                  <Moment format="Do MMMM">{row.publish_date}</Moment>
                </BoldTypography>
              </TableCellBody>
              <TableCellBody>
                <BoldTypography>{row.media_id}</BoldTypography>
              </TableCellBody>
              <TableCellBody>{row.duration}</TableCellBody>
              {!isPublisherView && (
                <TableCellBody sx={{ minWidth: '200px' }}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                      <span onClick={() => onSetAddVideoPreview(row)} style={{ cursor: 'pointer' }}>
                        <img width="28" src={plusButton} alt="plus button" />
                      </span>
                    </Grid>
                    <Grid item>
                      <PencilIcon style={{ cursor: 'pointer' }} onClick={() => onSetEditVideoPreview(row)} />
                    </Grid>
                    <Grid item>
                      <span onClick={() => onRemoveVideo(row.id)} style={{ cursor: 'pointer' }}>
                        <img width="28" src={removeButton} alt="remove button" />
                      </span>
                    </Grid>
                  </Grid>
                </TableCellBody>
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
