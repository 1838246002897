import * as React from 'react';
import Helmet from 'react-helmet';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import logo from '../pixels-ai-logo-white.svg';
import { ReactComponent as PIcon } from '../icons/short_logo_white.svg';
import { ReactComponent as AccountIcon } from '../icons/account.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { localStorageToJSON } from '../utils/common';
import { useEffect, useState } from 'react';
import { DrawerListItemText } from './common/TextLabel';
import Menu, { DefaultSection as MenuDefaultSection } from './menus/Menu';
import DemoMenu, { DefaultSection as DemoMenuDefaultSection } from './menus/DemoMenu';
import PublisherViewOfRightsholderMenu, {
  DefaultSection as PublisherViewOfRightsholderMenuDefaultSection,
} from './menus/PublisherViewOfRightsholderMenu';
import RightsholderViewOfPublisherMenu, {
  DefaultSection as RightsholderViewOfPublisherMenuDefaultSection,
} from './menus/RightsholderViewOfPublisherMenu';
import MainSections from './sections/MainSections';
import DemoSections from './sections/DemoSections';
import AdminSections from './sections/AdminSections';
import MenuItem from './menus/MenuItem';
import { Playlist } from '../api/playlist';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    fontFamily: 'ProximaNovaSemiBold',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface DashboardProps {
  section: string;
  demoPage?: boolean;
  adminPage?: boolean;
}

type Organisation = {
  domain: string;
  is_demo: boolean;
  is_publisher: boolean;
  is_rightsholder: boolean;
  playlists: Playlist[];
};

const Dashboard: React.FC<DashboardProps> = ({ section, demoPage, adminPage }: DashboardProps) => {
  const { playlist } = useParams();
  const selectedPlaylist = playlist;
  const [publishers, setPublishers] = useState<Organisation[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [selectedPublisher, setSelectedPublisher] = useState(publishers[0]?.domain);
  const [isDemo, setIsDemo] = useState(false);
  const [isRightholderView, setIsRightsholderView] = useState(false);
  const [isPublisherView, setIsPublisherView] = useState(false);

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  let navigate = useNavigate();

  const isAdmin = localStorage.getItem('is_admin') === 'true';
  const logOut = () => {
    if (window.localStorage) {
      localStorage.setItem('token', '');
      localStorage.setItem('pubAccess', '');
      localStorage.setItem('selectedPublisher', '');
    }
    navigate('/', { replace: true });
  };

  useEffect(() => {
    const organisations = localStorageToJSON('pubAccess');
    setPublishers(organisations);
    const domains = organisations.map(({ domain }) => domain);
    const selectedPublication = localStorage.getItem('selectedPublisher');
    //ensure selected domain is actually in the list of domains...
    const publisher = publishers.find(({ domain }) => domain === selectedPublication) || domains[0];
    setSelectedPublisher(publisher);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedPublisher) return;
    localStorage.setItem('selectedPublisher', selectedPublisher);
    const isDemo = publishers.some(({ domain, is_demo }) => domain === selectedPublisher && Boolean(is_demo));
    setIsDemo(isDemo);
    const isRightholderView = publishers.some(
      ({ domain, is_rightsholder }) => domain === selectedPublisher && Boolean(is_rightsholder)
    );
    setIsRightsholderView(isRightholderView);
    const isPublisherView = publishers.some(
      ({ domain, is_publisher }) => domain === selectedPublisher && Boolean(is_publisher)
    );
    setIsPublisherView(isPublisherView);
    const publisher = publishers.find(({ domain }) => domain === selectedPublisher);
    setPlaylists(publisher?.playlists || []);
    if (window.localStorage) {
      localStorage.setItem('isRightholderView', isRightholderView.toString());
      localStorage.setItem('isPublisherView', isPublisherView.toString());
      localStorage.setItem('playlists', playlists.toString());
    }
    if (!adminPage) {
      if (!isDemo) {
        if (isRightholderView) {
          navigate(PublisherViewOfRightsholderMenuDefaultSection());
        } else if (isPublisherView) {
          navigate(RightsholderViewOfPublisherMenuDefaultSection());
        } else {
          navigate(MenuDefaultSection());
        }
      } else if (isDemo) {
        navigate(DemoMenuDefaultSection());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet title={'Dashboard'}></Helmet>
      <CssBaseline />
      {!open && (
        <ChevronRightIcon
          className={open ? 'show' : 'hide'}
          style={{
            color: 'white',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: 1300,
            backgroundColor: '#3355D1',
            borderRadius: '50%',
            border: '1px solid rgb(255, 255, 255, 0.4)',
            top: 18,
            boxSizing: 'content-box',
            left: 59,
            padding: 2,
            transition: 'opacity 0.3s',
            opacity: 1,
          }}
          onClick={toggleDrawer}
        />
      )}

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: '#3355D1',
            color: 'white',
          },
        }}
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: [1],
          }}
        >
          <header
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Link
              style={{
                paddingLeft: 16,
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
              }}
              href="/"
            >
              {open && <img onClick={toggleDrawer} src={logo} alt="Pixels" />}
              {!open && <PIcon />}
            </Link>
          </header>
          {open && (
            <ChevronLeftIcon
              style={{
                color: 'white',
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
              }}
              onClick={toggleDrawer}
            />
          )}
        </Toolbar>
        <Divider />
        <List>
          {!isDemo && !isRightholderView && !isPublisherView && <Menu open={open} playlist={playlists[0]?.slug} />}
          {!isDemo && isRightholderView && !isPublisherView && <PublisherViewOfRightsholderMenu open={open} />}
          {!isDemo && !isRightholderView && isPublisherView && <RightsholderViewOfPublisherMenu open={open} />}
          {isDemo && <DemoMenu open={open} isAdmin={isAdmin} />}
        </List>
        <List style={{ position: 'fixed', bottom: 0, width: open ? drawerWidth : 72 }}>
          {/* removing as not currently used... */}
          {/* {!isAdmin && (
            <ListItem button>
              <ListItemIcon>
                <AccountIcon />
              </ListItemIcon>
              <DrawerListItemText disableTypography={true} primary="Account" />
            </ListItem>
          )} */}
          {isAdmin && (
            <>
              <MenuItem open={open} path="/publishers" label="Publishers">
                <AccountIcon />
              </MenuItem>
              <MenuItem open={open} path="/users" label="Users">
                <AccountIcon />
              </MenuItem>
            </>
          )}
          <ListItemButton onClick={logOut}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <DrawerListItemText disableTypography={true} primary="Log Out" />
          </ListItemButton>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {!isDemo && (
            <MainSections
              section={section}
              publishers={publishers}
              selectedPublisher={selectedPublisher}
              setPublisher={setSelectedPublisher}
              playlists={playlists}
              selectedPlaylist={selectedPlaylist}
            />
          )}
          {isDemo && (
            <DemoSections
              section={section}
              publishers={publishers}
              selectedPublisher={selectedPublisher}
              setPublisher={setSelectedPublisher}
            />
          )}
          {isAdmin && (
            <AdminSections
              section={section}
              publishers={publishers}
              selectedPublisher={selectedPublisher}
              setPublisher={setSelectedPublisher}
              isDemo={isDemo}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;
