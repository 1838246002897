import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { PublicationSelector } from './PublicationSelector';
import { useDispatch, useSelector } from 'react-redux';
import { apiReportUrlProvider } from '../store/reportSlice';
import {
  ButtonGroup,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Toolbar
} from '@mui/material';
import { Heading } from './common/Heading';

const ReportFrame = styled('iframe')`
    width: 100%;
    height: 85vh;
    border: none;
    background-color: transparent;
`;

export default function Report({ setPublisher, publishers, selectedPublisher }) {
  const dispatch = useDispatch();

  const { url, busy, formatFilter } = useSelector((state: any) => state.report);

  useEffect(() => {
    if (!selectedPublisher) return;
    dispatch<any>(
      apiReportUrlProvider({
        publisher: selectedPublisher,
        params: {
          // date_range: 'previous7days',
          dashboardId: 72,
          format: formatFilter,
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher, formatFilter]);

  return (
    <React.Fragment>
      <Toolbar style={{ paddingBottom: '0', paddingLeft: '15px', justifyContent: 'flex-end' }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Heading>Format</Heading>
          </Grid>
          <Grid item>
            <ButtonGroup>
              <FormControl
                variant="outlined"
                margin="dense"
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <Select
                  id="format-select"
                  value={formatFilter}
                  onChange={(event) => {
                    dispatch({ type: 'SET_FILTER_FORMAT', payload: event.target.value });
                  }}
                  sx={{
                    fontFamily: 'ProximaNovaBold',
                    textTransform: 'none',
                    color: '#000',
                    height: '43px',
                    width: '200px',
                    marginRight: '30px',
                  }}
                >
                  {['horizontal', 'vertical'].map((format: string) => (
                    <MenuItem value={format}>{format}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ButtonGroup>
          </Grid>
        </Grid>
        <PublicationSelector
          selectedPublication={selectedPublisher}
          publishers={publishers}
          onChange={setPublisher}
        />
      </Toolbar>
      {busy &&
        <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success"/>}
      <ReportFrame src={url}></ReportFrame>
    </React.Fragment>
  );
}
