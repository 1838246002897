import * as React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, LinearProgress, Tab, Tabs } from '@mui/material';
import ToolBarImageSwap from './ToolBarImageSwap';
import { getApiBaseUrl } from '../utils/env';
import PaginationTable from './Pagination';
import { apiArticleProvider } from '../store/imageSwapSlice';
import { useEffect } from 'react';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';
import { ImageSwapArticleTable } from './ImageSwapArticleTable';
import { KeywordHeading } from './common/Heading';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

export default function ImageSwap({ setPublisher, publishers, selectedPublisher, type, title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { articlesFilter, isGroupByKeyword, articles, articleGroupByKeyword, pagination, filteredTopic, busy } =
    useSelector((state: any) => state.imageSwap);

  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);
  const handleClose = () => setArticleVideoPreview(false);
  const [orderDirection, setOrderDirection] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('traffic');
  const [loadingId, setLoadingId] = React.useState(null);

  const sorting = type => () => {
    if (orderBy !== type) {
      setOrderDirection('desc');
    }
    if (orderBy === type) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(type);
  };

  const updateList = () => {
    dispatch<any>(
      apiArticleProvider({
        publisher: selectedPublisher,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByDate: articlesFilter,
        orderBy: orderBy,
        orderDirection: orderDirection,
        isGroupByKeyword: isGroupByKeyword,
        keyword_id: filteredTopic.join(','),
        type,
      })
    );
  };

  const createPost = (articleId, videoId) => {
    setLoadingId(articleId);
    axios
      .post(`${getApiBaseUrl()}/${selectedPublisher}/article-match/${articleId}/send/?video_id=${videoId}`, {})
      .then(response => {
        if (response.status === 201) {
          dispatch({ type: 'MATCH_UPDATE', payload: articleId });
          handleClose();
          updateList();
          setLoadingId(null);
        }
      });
  };

  const removeVideo = (articleId, videoId) => {
    axios.delete(`${getApiBaseUrl()}/${selectedPublisher}/article-match/${articleId}?video_id=${videoId}`).then(() => {
      handleClose();
      updateList();
    });
  };

  useEffect(() => {
    if (!selectedPublisher) return;
    dispatch<any>(
      apiArticleProvider({
        publisher: selectedPublisher,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        filterByDate: articlesFilter,
        orderBy: orderBy,
        orderDirection: orderDirection,
        isGroupByKeyword,
        keyword_id: filteredTopic.join(','),
        type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPublisher,
    pagination.itemsPerPage,
    pagination.currentPage,
    articlesFilter,
    orderDirection,
    orderBy,
    isGroupByKeyword,
  ]);

  const currentOrg = publishers.find(({ domain }) => domain === selectedPublisher);

  return articles ? (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoImageSwapDialog
          open={Boolean(articleVideoPreview)}
          handleClose={handleClose}
          article={articleVideoPreview}
          onRemoveVideo={videoId => removeVideo(articleVideoPreview?.id, videoId)}
          onAddVideo={({ video_id: videoId }) => createPost(articleVideoPreview?.id, videoId)}
          type={type}
        />
      )}

      <ToolBarImageSwap
        title={title}
        setPublisher={setPublisher}
        publishers={publishers}
        selectedPublisher={selectedPublisher}
      />
      {!isGroupByKeyword && articles && (
        <>
          <Tabs
            value={type}
            onChange={(e, value) => {
              setOrderBy(value === 'no-matches' ? 'traffic' : 'score')
              navigate(`/dashboard/video-swap${value === 'review' ? '' : `/` + value}`);
            }}
            aria-label="basic tabs example"
            sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
          >
            <Tab label="Image articles" value={'review'} />
            <Tab label="Video articles" value={'improve'} />
            { !currentOrg?.autopublish && <Tab label="Swapped" value={'swapped'} /> }
            <Tab label="Needs better video" value={'no-matches'} />
          </Tabs>
          <Box sx={{ 'position': 'relative' }}>
            {busy && articleGroupByKeyword && <LinearProgress sx={{ 'position': 'absolute', 'top': 0, 'width': '100%' }} color="success" />}
            {/*{busy && articleGroupByKeyword && <CircularProgress size={20} sx={{ 'position': 'absolute', 'top': -28, 'right': 12 }} color="primary" />}*/}
            <ImageSwapArticleTable
              articles={articles}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onSortByTraffic={sorting('traffic')}
              onSortByDate={sorting('date')}
              onSortByScore={sorting('score')}
              onSetArticleForPreview={setArticleVideoPreview}
              loadingId={loadingId}
              articleDemo={false}
              autopublish={currentOrg?.autopublish}
              selectedPublisher={selectedPublisher}
            />
          </Box>

        </>
      )}
      {isGroupByKeyword &&
        Object.entries<Record<string, any>>(articleGroupByKeyword).map(([keyword, items]) => (
          <div>
            <KeywordHeading>{keyword}</KeywordHeading>
            <ImageSwapArticleTable
              articles={items.image_articles}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onSortByTraffic={sorting('traffic')}
              onSortByDate={sorting('date')}
              onSortByScore={sorting('score')}
              onSetArticleForPreview={setArticleVideoPreview}
              loadingId={loadingId}
              articleDemo={false}
              autopublish={currentOrg?.autopublish}
              selectedPublisher={selectedPublisher}
            />
          </div>
        ))}
      {busy && articleGroupByKeyword && <LinearProgress color="success" />}
      <PaginationTable
        defaultSize={20}
        busy={busy}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </React.Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
