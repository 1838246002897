import { capitalise } from '../utils/common';
import { BoldTypographyLarge } from './common/TextLabel';

export default function ProducerHeading({ row }) {
  if (row.producer === 'true') {
    //@TODO: mask bug coming from python backend... to remove when fixed
    row.producer = null;
  }
  const producer = row.rightsholder ? `${capitalise(row.rightsholder)}` : row.producer ? `BY ${row.producer}` : '';
  return <BoldTypographyLarge>{producer}</BoldTypographyLarge>;
}
