import PublicLayout2 from './PublicLayout2';
import { styled } from '@mui/material/styles';
import Helmet from 'react-helmet';
import { Button, GlobalStyles, Grid, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from '../../icons/button-arrow.svg';
import PropTypes from 'prop-types';
import { ContentWrap, GridCenterAligned2, GridContentItem2 } from '../common/GridLayout';
import { BaseTypography, Headline, TextHighlighted } from '../common/PublicTypography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import BrandedCarousel2 from '../BrandCarousel2';
import Header from './component/Header';
import MobileDrawer from './component/Drawer';
import MarketingVideo from '../marketing-video/marketing-video';
import MarketingImage from '../marketing-image/marketing-image';

export const SubmitBtn = styled(Button)`
  font-size: 18px;
  text-transform: initial;
  padding: 8px 24px;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 22px;
    margin: 40px 0;
    padding: 10px 28px;
  }

  & .MuiButton-endIcon {
    margin-left: 24px;
  }
`;

const Subheadline = styled(BaseTypography)`
  margin: 24px 0 44px;
  font-size: 22px;
  line-height: 1.5;
  max-width: 440px;
  color: #e0e9ff;
`;

export const shadeBgStyles = theme => ({
  body: {
    color: '#B4C9FF',
  },
  '#root > div': {
    minHeight: '100vh',
  },
});

const HomeHeadline = styled(Headline)`
  font-size: 40px;
  line-height: 1.14;
  font-weight: 500;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: 70px;
  }
`;

const BenefitHeadline = styled(BaseTypography)`
  padding-left: 25px;
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    width: 20px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    content: ' ';
  }

  &.auto:after {
    background-image: url('/v3/auto.svg');
  }

  &.megaphone:after {
    background-image: url('/v2/megaphone.svg');
  }

  &.zerocost:after {
    background-image: url('/v3/zerocost.svg');
  }
`;

const BenefitSeparator = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;

  &:before {
    position: absolute;
    content: ' ';
    text-align: center;
    background: rgba(180, 201, 255, 0.71);
    width: 100%;
    height: 2px;
    top: -16px;

    ${props => props.theme.breakpoints.up('md')} {
      width: 2px;
      height: 100%;
      left: -32px;
      top: 0;
    }
  }
`;

const VideoRoot = styled(Box)`
  position: absolute;
  left: 50%;
  width: calc(100vw / 2);
  max-width: 960px;
  transform: none;
  height: 100%;
  z-index: -1;
  display: none;

  ${props => props.theme.breakpoints.up('lg')} {
    display: block;
  }

  > video {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
  }
`;

const ContentRoot = styled(Box)`
  flex: 1;
  position: relative;

  ${props => props.theme.breakpoints.up('md')} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const ContentInner = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing(2.6)};
  padding-right: ${({ theme }) => theme.spacing(2.6)};

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 800px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const MobileVideoShowcase = styled(Grid)`
  position: relative;
  margin-bottom: 20px;

  .submit-btn-mobile {
    position: relative;
    z-index: 1;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    display: none;
  }
`;

const MobileVideoInner = styled(Box)`
  position: absolute;
  width: calc(100% + 40px);
  left: -20px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  video {
    position: relative;
    width: 100%;
  }
`;

const MobileVideoRoot = styled(Box)`
  position: relative;
  min-height: 400px;
  ${props => props.theme.breakpoints.up('md')} {
    min-height: 1000px;
  }
  margin-top: 28px;
`;

const HighlightDesc = styled(Typography)`
  line-height: 125%;
  margin-top: 12px;
  margin-bottom: 16px;
  ${props => props.theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: 32px;
  }
`;

const GridRootHighlight = styled(Grid)`
  ${props => props.theme.breakpoints.up('md')} {
    margin-right: -32px;
    width: calc(100% + 32px + 32px);
  }
`;

const Link = styled('a')`
  all: unset;
  cursor: pointer;
`;

function Home({ className }) {
  const [drawerState, setDrawerState] = useState(false);
  return (
    <PublicLayout2 className={className} page={'home'}>
      <GlobalStyles styles={shadeBgStyles} />
      <Helmet title="Pixels AI - It Pays To Go Vertical">
        <meta
          name="description"
          content="Unlock revenue potential with Pixels AI. Explore vertical video ads for the open web with vRoll&#8482;. Easy, automated site-wide integration to boost your earnings without any upfront costs."
        />
      </Helmet>
      <ContentRoot maxWidth={1440}>
        <VideoRoot>
          <video width={'100%'} height={'100%'} autoPlay loop muted>
            <source src="/v3/hero.mp4" type="video/mp4" />
          </video>
        </VideoRoot>
        <Header setDrawerState={setDrawerState} />
        <ContentRoot className={'content-root'}>
          <ContentInner className={'content-inner'}>
            <GridCenterAligned2 container maxWidth={1440}>
              <GridContentItem2 item xs={12} lg={6}>
                <ContentWrap maxWidth={1440} paddingBottom={12}>
                  <Grid container flexDirection={'column'} justifyContent={'center'} maxWidth={1440}>
                    <Grid item>
                      <HomeHeadline variant={'h1'}>
                        It pays to go <TextHighlighted>vertical</TextHighlighted>
                      </HomeHeadline>
                      <Subheadline paragraph>Vertical video for the open web. With Ads.</Subheadline>
                      <MobileVideoShowcase item>
                        <SubmitBtn
                          className={'submit-btn-mobile'}
                          href={'/instream-revenue-uplift-calculator/'}
                          variant={'outlined-x'}
                          endIcon={<ArrowIcon />}
                        >
                          Get Started
                        </SubmitBtn>
                        <MobileVideoRoot>
                          <MobileVideoInner>
                            <video autoPlay loop muted playsInline poster={'/v3/hero-poster.png'}>
                              <source src="/v3/hero.mp4" type="video/mp4" />
                            </video>
                          </MobileVideoInner>
                        </MobileVideoRoot>
                      </MobileVideoShowcase>
                      <GridRootHighlight container spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
                        <Grid item xs={12} md={4}>
                          <Link href={'/contact/'}>
                            <BenefitHeadline className={'megaphone'} variant={'h2'}>
                            vRoll&#8482;
                            </BenefitHeadline>
                            <HighlightDesc>Exclusive ads that generate revenue.</HighlightDesc>
                          </Link>
                        </Grid>
                        <Grid sx={{ position: 'relative' }} item xs={12} md={4}>
                          <BenefitSeparator>
                            <Link href={'/sustainability/'}>
                              <BenefitHeadline className={'zerocost'} variant={'h2'}>
                                Zero Cost
                              </BenefitHeadline>
                              <HighlightDesc>No upfront cost. Just pure revenue potential.</HighlightDesc>
                            </Link>
                          </BenefitSeparator>
                        </Grid>
                        <Grid sx={{ position: 'relative' }} item xs={12} md={4}>
                          <BenefitSeparator>
                            <BenefitHeadline className={'auto'} variant={'h2'}>
                              Automated
                            </BenefitHeadline>
                            <HighlightDesc>Site-wide integration made easy.</HighlightDesc>
                          </BenefitSeparator>
                        </Grid>
                      </GridRootHighlight>
                    </Grid>
                    <Grid
                      item
                      sx={theme => ({
                        display: 'none',
                        [theme.breakpoints.up('lg')]: {
                          display: 'flex',
                        },
                      })}
                    >
                      <SubmitBtn
                        href={'/instream-revenue-uplift-calculator/'}
                        variant={'outlined-x'}
                        endIcon={<ArrowIcon />}
                      >
                        Get Started
                      </SubmitBtn>
                    </Grid>
                  </Grid>
                </ContentWrap>
              </GridContentItem2>
              <Grid
                item
                xs={12}
                lg={6}
                sx={theme => ({
                  display: 'flex',
                  flexDirection: 'column',
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                })}
              ></Grid>
            </GridCenterAligned2>
          </ContentInner>
        </ContentRoot>
      </ContentRoot>
      <BrandedCarousel2 />
      <MarketingImage />
      <MarketingVideo />
      <MobileDrawer setDrawerState={setDrawerState} drawerState={drawerState} />
    </PublicLayout2>
  );
}

Home.propTypes = {
  className: PropTypes.string,
};

export default styled(Home)`
  ${props => props.theme.breakpoints.up('md')} {
    .content-root {
      display: flex;
    }

    .content-inner {
      display: flex;
      flex-direction: column;

      > div:first-of-type {
        flex: 1;
      }
    }
  }

  .content-inner {
    max-width: none;
    padding: 0;
    overflow: hidden;
  }
`;
