import * as React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import UserCalculator from './calculator/UserCalculator';
import PublicCalculator from './calculator/PublicCalculator';
import { useLocation } from 'react-router';
import { CalculatorContext } from '../context/calculator/CalculatorProvider';
import {
  formatCurrency,
  getCurrencyRate,
  getCurrencySymbol,
  numberWithoutComma,
  updateState
} from './calculator/utils';

const PERCENTAGE_AD_REQS_FROM_CTP = 0.1;
const PERCENTAGE_AD_REQS_FROM_AUTO = 0.7;
const CTP_HEADERBIDDING_UPLIFT = 1.25;
const AUTO_HEADERBIDDING_UPLIFT = 1.34;

export default function Calculator({ selectedPublisher, publishers, setPublisher }) {
  const location = useLocation();
  const {
    calc,
    dispatch
  } = useContext(CalculatorContext);

  const {
    dailyPageviews, monthlyPageviews, imageToVideoRatioPercentage, matchPercentage, fillRatePercentage,
    ctpCpm, autoCpm, upliftProg, upliftDirect, currency,
    isCtp, isProgrammatic, includeHeaderbidding, potentialVideoMatches, adReqs, upliftedCpm
  } = calc;
  const update = updateState(dispatch);


  useEffect(() => {
    const dailyVisit = Math.floor(numberWithoutComma(monthlyPageviews)  / 30);
    update('dailyPageviews', dailyVisit);
    update('potentialVideoMatches', (dailyVisit || 1000000) * (imageToVideoRatioPercentage / 100) * (matchPercentage / 100));

    if (isCtp) {
      update('adReqs', potentialVideoMatches * PERCENTAGE_AD_REQS_FROM_CTP * (fillRatePercentage / 100));
    } else {
      update('adReqs', potentialVideoMatches * PERCENTAGE_AD_REQS_FROM_AUTO * (fillRatePercentage / 100));
    }
    let pixels_relevancy_uplift: number;
    if (isProgrammatic) {
      pixels_relevancy_uplift = upliftProg;
    } else {
      pixels_relevancy_uplift = upliftProg + (upliftProg * upliftDirect);
    }

    const setUpliftedCpm = update('upliftedCpm');
    switch (true) {
      case isCtp && !includeHeaderbidding:
        setUpliftedCpm(ctpCpm * pixels_relevancy_uplift);
        break;
      case isCtp && includeHeaderbidding:
        setUpliftedCpm(ctpCpm * CTP_HEADERBIDDING_UPLIFT * pixels_relevancy_uplift);
        break;
      case !isCtp && !includeHeaderbidding:
        setUpliftedCpm(autoCpm * pixels_relevancy_uplift);
        break;
      case !isCtp && includeHeaderbidding:
        setUpliftedCpm(autoCpm * AUTO_HEADERBIDDING_UPLIFT * pixels_relevancy_uplift);
        break;
    }
    update('lostRevenue', (upliftedCpm * adReqs) / 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adReqs,
    autoCpm,
    ctpCpm,
    dailyPageviews,
    monthlyPageviews,
    fillRatePercentage,
    imageToVideoRatioPercentage,
    includeHeaderbidding,
    isCtp,
    isProgrammatic,
    matchPercentage,
    potentialVideoMatches,
    upliftDirect,
    upliftProg,
    upliftedCpm,
  ]);

  const CalculatorForm = /instream-revenue-uplift-calculator/.test(location.pathname) ? PublicCalculator : UserCalculator;

  return (
    <React.Fragment>
      <CalculatorForm
        publishers={publishers}
        setPublisher={setPublisher}
        selectedPublisher={selectedPublisher}
        formatCurrency={(amount) => formatCurrency(amount, getCurrencySymbol(currency), getCurrencyRate(calc))}/>
    </React.Fragment>
  );
}

Calculator.propTypes = {
  selectedPublisher: PropTypes.string,
  setPublisher: PropTypes.func,
  publisher: PropTypes.array
}