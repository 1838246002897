import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FormControl, Grid, TextField } from '@mui/material';

import Toolbar from '@mui/material/Toolbar';

import { PublicationSelector } from './PublicationSelector';
import { Heading } from './common/Heading';
import { styled } from '@mui/material/styles';

function ToolBarPixelsSearch({ setPublisher, publishers, selectedPublisher, title = 'Headline Search' }) {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    searchText: '',
  });

  const changeData =
    key =>
    ({ target: { value } }) =>
      setData({
        ...data,
        [key]: value,
      });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch({ type: 'SET_PIXELS_SEARCH_TEXT', payload: data.searchText });
  };

  return (
    <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px', gap: '8px' }}>
      <Grid item xs={10} sx={{ flex: 1 }}>
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
              <Heading>{title}</Heading>
            </Grid>
            <Grid item flex={1}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type={'text'}
                  value={data.searchText}
                  label="Search text"
                  variant="outlined"
                  placeholder={'eg. My article headline text'}
                  onChange={changeData('searchText')}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button color="primary" variant="contained" type={'submit'}>
                Go
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item xs={2}>
        <PublicationSelector selectedPublication={selectedPublisher} publishers={publishers} onChange={setPublisher} />
      </Grid>
    </Toolbar>
  );
}

export default styled(ToolBarPixelsSearch)`
  margin-bottom: 40px;
`;
