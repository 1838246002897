import MenuItem from './MenuItem';
import { ReactComponent as PlaylistIcon } from '../../icons/playlist.svg';

export default function PublisherViewOfRightsholderMenu({ open }) {
  return (
    <>
      <MenuItem open={open} path="/dashboard/video-library" label="Video Library">
        <PlaylistIcon />
      </MenuItem>
    </>
  );
}

export function DefaultSection() {
  return '/dashboard/video-library';
}
