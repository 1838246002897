import Calculator from '../Calculator';

export default function DemoSections({ section, publishers, selectedPublisher, setPublisher }) {
  return (
    <>
      {section === 'calculator' && (
        <Calculator selectedPublisher={selectedPublisher} publishers={publishers} setPublisher={setPublisher} />
      )}
    </>
  );
}
