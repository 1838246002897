import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchPixelsSearchSuccess = payload => ({
  type: 'FETCH_PIXELS_SEARCH_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_PIXELS_SEARCH_BUSY',
  payload,
});

export const apiPixelsSearchProvider =
  ({ publisher, searchText }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const data = await axios
        .post(`${getApiBaseUrl()}/${publisher}/pixels-search`, {
          search_text: searchText,
        })
        .then(response => response.data)
        .catch(ex => {
          console.error(ex);
          alert('Unable to perform search.');
        });
      dispatch(fetchPixelsSearchSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface EngageState {
  searchText: string;
  videos: any;
  busy: boolean;
}

const initialState: EngageState = {
  searchText: '',
  videos: [],
  busy: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PIXELS_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.payload,
      };
    case 'FETCH_PIXELS_SEARCH_SUCCESS':
      return {
        ...state,
        videos: action.payload,
      };
    case 'FETCH_PIXELS_SEARCH_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
