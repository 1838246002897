import * as React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress, Tab, Tabs } from '@mui/material';
import PaginationTable from './Pagination';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { apiPlaylistProvider } from '../store/playlistSlice';
import { PlaylistTable } from './PlaylistTable';
import ToolBarPlaylist from './ToolBarPlaylist';
import { VideoSinglePreview } from './modal/VideoSinglePreview';
import { getApiBaseUrl } from '../utils/env';
import { AddVideoDialog } from './modal/AddVideoDialog';
import { EditVideoDialog } from './modal/EditVideoDialog';
import { useNavigate } from 'react-router';
import { Playlist as PlaylistObj } from '../api/playlist';

export default function Playlist({ setPublisher, publishers, selectedPublisher, playlists, selectedPlaylist }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { formatFilter, videos, pagination, busy } = useSelector((state: any) => state.playlist);

  const [videoPreview, setVideoPreview] = React.useState<any>(false);
  const handleClose = () => setVideoPreview(false);
  const [addVideoPreview, setAddVideoPreview] = React.useState<any>(false);
  const [editVideoPreview, setEditVideoPreview] = React.useState<any>(false);
  const addHandleClose = () => setAddVideoPreview(false);
  const editHandleClose = () => setEditVideoPreview(false);

  const updateList = () => {
    dispatch<any>(
      apiPlaylistProvider({
        publisher: selectedPublisher,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        selectedPlaylist,
        formatFilter,
      })
    );
  };

  const saveVideo = data => {
    addHandleClose();
    axios
      .post(
        `${getApiBaseUrl()}/${selectedPublisher}/playlist/${selectedPlaylist}/${formatFilter}?video_id=${
          data.videoIdToAdd
        }&pos=${data.pos}`,
        {}
      )
      .then(response => {
        if (response.status === 201) {
          updateList();
        }
      })
      .catch(ex => {
        alert(ex);
      });
  };

  const editVideo = (data: { id: number; title: string }) => {
    editHandleClose();
    axios
      .put(`${getApiBaseUrl()}/${selectedPublisher}/playlist/${selectedPlaylist}/${formatFilter}/${data.id}`, {
        video_title: data.title,
      })
      .then(response => {
        if (response.status === 200) {
          updateList();
        }
      })
      .catch(ex => {
        alert(ex);
      });
  };

  const removeVideo = (id: number) => {
    if (!window.confirm('Are you sure you want to remove this playlist video?')) {
      return;
    }
    axios
      .delete(`${getApiBaseUrl()}/${selectedPublisher}/playlist/${selectedPlaylist}/${formatFilter}/${id}`)
      .then(() => {
        updateList();
      });
  };

  useEffect(() => {
    if (!selectedPublisher) return;
    dispatch<any>(
      apiPlaylistProvider({
        publisher: selectedPublisher,
        limit: pagination.itemsPerPage,
        page: pagination.currentPage,
        selectedPlaylist,
        formatFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher, pagination.itemsPerPage, pagination.currentPage, formatFilter, selectedPlaylist]);

  return videos ? (
    <React.Fragment>
      {videoPreview && (
        <VideoSinglePreview
          open={Boolean(videoPreview)}
          handleClose={handleClose}
          video={videoPreview}
          format={formatFilter}
          transcript={videoPreview.transcript}
        />
      )}
      {addVideoPreview && (
        <AddVideoDialog
          onSave={saveVideo}
          open={Boolean(addVideoPreview)}
          handleClose={addHandleClose}
          video={addVideoPreview}
        />
      )}
      {editVideoPreview && (
        <EditVideoDialog
          onSave={editVideo}
          open={Boolean(editVideoPreview)}
          handleClose={editHandleClose}
          video={editVideoPreview}
        />
      )}

      <ToolBarPlaylist
        setPublisher={setPublisher}
        publishers={publishers}
        selectedPublisher={selectedPublisher}
        playlists={playlists}
      />
      {videos && (
        <>
          <Tabs
            value={selectedPlaylist}
            onChange={(e, value) => {
              navigate(`/dashboard/playlist/${value}`);
            }}
            aria-label="basic tabs example"
            sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
          >
            {playlists.map((playlist: PlaylistObj) => (
              <Tab key={playlist.slug} label={playlist.name} value={playlist.slug} />
            ))}
          </Tabs>
          <Box sx={{ position: 'relative' }}>
            {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
            <PlaylistTable
              videos={videos}
              onSetVideoPreview={setVideoPreview}
              onSetAddVideoPreview={setAddVideoPreview}
              onSetEditVideoPreview={setEditVideoPreview}
              onRemoveVideo={removeVideo}
              format={formatFilter}
            />
          </Box>
        </>
      )}
      {busy && <LinearProgress color="success" />}
      <PaginationTable
        defaultSize={20}
        busy={busy}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </React.Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
