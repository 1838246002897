import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { apiPixelsSearchProvider } from '../store/pixelsSearchSlice';
import { PixelsSearchVideoTable } from './PixelsSearchVideoTable';
import ToolBarPixelsSearch from './ToolBarPixelsSearch';
import { VideoSinglePreview } from './modal/VideoSinglePreview';

export default function PixelsSearch({ setPublisher, publishers, selectedPublisher }) {
  const dispatch = useDispatch();

  const { searchText, videos, busy } = useSelector((state: any) => state.pixelsSearch);

  const [videoPreview, setVideoPreview] = React.useState<any>(false);
  const handleClose = () => setVideoPreview(false);

  useEffect(() => {
    if (!selectedPublisher || !searchText) return;
    dispatch<any>(
      apiPixelsSearchProvider({
        publisher: selectedPublisher,
        searchText,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher, searchText]);

  return (
    <>
      <ToolBarPixelsSearch setPublisher={setPublisher} publishers={publishers} selectedPublisher={selectedPublisher} />
      {videos ? (
        <React.Fragment>
          {videoPreview && (
            <VideoSinglePreview open={Boolean(videoPreview)} handleClose={handleClose} video={videoPreview} transcript={null} />
          )}

          {videos && (
            <>
              <Box sx={{ position: 'relative' }}>
                {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
                <PixelsSearchVideoTable videos={videos} onSetVideoForPreview={setVideoPreview} />
              </Box>
            </>
          )}
          {busy && <LinearProgress color="success" />}
        </React.Fragment>
      ) : (
        <CircularProgress style={{ padding: '5px' }} />
      )}
    </>
  );
}
