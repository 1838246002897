import * as React from 'react';

import { Grid } from '@mui/material';

import Toolbar from '@mui/material/Toolbar';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { PublicationSelector } from '../PublicationSelector';
import { Heading } from '../common/Heading';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#3355D1',
    },
  },
});

/**
 * Note: Created as a preparation for toolbar refactoring. All toolbars should extend this.
 */

function ToolbarRoot({ setPublisher, publishers, selectedPublisher, title = '' }) {
  return (
    <ThemeProvider theme={theme}>
      <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px' }}>
        <Grid item xs={10} sx={{ flex: 1 }}>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
              <Heading>{title}</Heading>
            </Grid>
            <Grid item>
            {/*  children here */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <PublicationSelector
            selectedPublication={selectedPublisher}
            publishers={publishers}
            onChange={setPublisher}
          />
        </Grid>
      </Toolbar>
    </ThemeProvider>
  );
}

export default styled(ToolbarRoot)`
  margin-bottom: 40px;
`;
