import * as React from 'react';
import axios from 'axios';

import { CircularProgress } from '@mui/material';
import ToolBarImageSwap from './ToolBarImageSwap';
import { getApiBaseUrl } from '../utils/env';
import { useEffect, useState } from 'react';
import { VideoImageSwapDialog } from './modal/VideoImageSwapDialog';
import { ImageSwapArticleTable } from './ImageSwapArticleTable';
import { urlAppendQs } from '../utils/common';
import { demoPlayerId } from '../constant/player';

export default function ImageSwapDemo({ setPublisher, publishers, selectedPublisher }) {
  const [articleVideoPreview, setArticleVideoPreview] = React.useState<any>(false);
  const handleClose = () => setArticleVideoPreview(false);
  const [orderDirection, setOrderDirection] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('video_score');
  const [ratio, setRatio] = React.useState(0);

  const [articles, setArticles] = useState([]);
  const sorting = type => () => {
    if (orderBy !== type) {
      setOrderDirection('desc');
    }
    if (orderBy === type) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(type);
  };

  useEffect(() => {
    axios
      .get(`${getApiBaseUrl()}/article-demo/${selectedPublisher}/articles`, {
        params: {
          page: 1,
          limit: 50,
        },
      })
      .then(({ data }) => {
        setArticles(data.items);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.get(`${getApiBaseUrl()}/article-demo/${selectedPublisher}/article-split`).then(({ data }) => setRatio(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return articles.length > 0 ? (
    <React.Fragment>
      {articleVideoPreview && (
        <VideoImageSwapDialog
          open={Boolean(articleVideoPreview)}
          handleClose={handleClose}
          article={articleVideoPreview}
          onRemoveVideo={videoId => {}}
          onAddVideo={({ video_id: videoId }) => {
            window.open(
              urlAppendQs(articleVideoPreview.url, {
                pixels_inject_media_id: articleVideoPreview.video_id,
                pixels_inject_player_id: demoPlayerId,
                pixels_inject_tag: articleVideoPreview.imageSelector,
              })
            );
          }}
        />
      )}
      <ToolBarImageSwap
        setPublisher={setPublisher}
        publishers={publishers}
        selectedPublisher={selectedPublisher}
        title={`Add videos - ${ratio.toFixed(0)}% image articles`}
      />

      {articles.length > 0 && (
        <ImageSwapArticleTable
          articles={articles}
          orderBy={orderBy}
          orderDirection={orderDirection}
          onSortByTraffic={sorting('traffic')}
          onSortByDate={sorting('publish_date')}
          onSortByScore={sorting('video_score')}
          onSetArticleForPreview={setArticleVideoPreview}
          loadingId={null}
          autopublish={false}
          articleDemo
          selectedPublisher={selectedPublisher}
        />
      )}
    </React.Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
