import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchPlaylistSuccess = payload => ({
  type: 'FETCH_PLAYLIST_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_PLAYLIST_BUSY',
  payload,
});

export const apiPlaylistProvider =
  ({ publisher, selectedPlaylist, formatFilter, ...params }) =>
  async dispatch => {
    dispatch(updateBusyIndicator(true));
    try {
      const { data } = await axios.get(`${getApiBaseUrl()}/${publisher}/playlist/${selectedPlaylist}/${formatFilter}`, {
        params,
      });
      dispatch(fetchPlaylistSuccess(data));
      dispatch(updateBusyIndicator(false));
    } catch (e) {
      dispatch(updateBusyIndicator(false));
      console.error(e);
    }
  };

export interface EngageState {
  videos: any;
  formatFilter: string;
  paginationPage: number;
  busy: boolean;
  paginationPerPage: number;
  pagination: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

const initialState: EngageState = {
  videos: [],
  paginationPage: 0,
  formatFilter: 'vertical',
  paginationPerPage: 20,
  busy: false,
  pagination: {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 20,
    totalItems: 0,
    totalPages: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    case 'FETCH_PLAYLIST_BUSY':
      return {
        ...state,
        busy: action.payload,
      };
    case 'FETCH_PLAYLIST_SUCCESS':
      let { items: videos, meta: pagination } = action.payload;
      return {
        ...state,
        videos,
        pagination,
      };
    case 'SET_FILTER_FORMAT':
      return {
        ...state,
        formatFilter: action.payload,
        pagination: {
          ...state.pagination,
          currentPage: 1,
        },
      };
    default:
      return state;
  }
};

export default reducer;
