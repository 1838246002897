import ImageSwap from '../ImageSwap';
import PixelsSearch from '../PixelsSearch';
import Playlist from '../Playlist';
import PromotedContentList from '../PromotedContentList';
import PromotedContentReport1 from '../PromotedContentReport1';
import PromotedContentReport2 from '../PromotedContentReport2';
import Report from '../Report';
import PVReport from '../PVReport';
import VideoLibrary from '../VideoLibrary';
import GapAnalysisReport from '../GapAnalysisReport';

export default function MainSections({
  section,
  publishers,
  selectedPublisher,
  setPublisher,
  playlists,
  selectedPlaylist,
}) {
  return (
    <>
      {section === 'report' && (
        <Report publishers={publishers} selectedPublisher={selectedPublisher} setPublisher={setPublisher} />
      )}
      {section === 'pv-report' && (
        <PVReport publishers={publishers} selectedPublisher={selectedPublisher} setPublisher={setPublisher} />
      )}
      {section === 'video-swap' && (
        <ImageSwap
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          type="review"
          title="Fast Match"
        />
      )}
      {section === 'video-swap-no-matches' && (
        <ImageSwap
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          type="no-matches"
          title="Needs better video"
        />
      )}
      {section === 'video-swap-swapped' && (
        <ImageSwap
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          type="swapped"
          title="Fast Match"
        />
      )}
      {section === 'video-swap-improve' && (
        <ImageSwap
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          type="improve"
          title="Fast Match"
        />
      )}
      {section === 'promoted-content-1' && (
        <PromotedContentReport1
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
        />
      )}
      {section === 'promoted-content-2' && (
        <PromotedContentReport2
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
        />
      )}
      {section === 'promoted-content-3' && (
        <PromotedContentList
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
        />
      )}
      {section === 'headline-search' && (
        <PixelsSearch publishers={publishers} selectedPublisher={selectedPublisher} setPublisher={setPublisher} />
      )}
      {section === 'video-library' && (
        <VideoLibrary publishers={publishers} selectedPublisher={selectedPublisher} setPublisher={setPublisher} />
      )}
      {section === 'playlist' && (
        <Playlist
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          playlists={playlists}
          selectedPlaylist={selectedPlaylist}
        />
      )}
      {section === 'gap-analysis' && (
        <GapAnalysisReport
          publishers={publishers}
          selectedPublisher={selectedPublisher}
          setPublisher={setPublisher}
          playlists={playlists}
        />
      )}
    </>
  );
}
