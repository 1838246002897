import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { apiReportUrlProvider } from '../store/reportSlice';
import { LinearProgress } from '@mui/material';
import ToolBarGapAnalysisReport from './ToolBarGapAnalysisReport';

const ReportFrame = styled('iframe')`
  width: 100%;
  height: 85vh;
  border: none;
  background-color: transparent;
`;

export default function GapAnalysisReport({ setPublisher, publishers, selectedPublisher, playlists }) {
  const dispatch = useDispatch();

  const { playlistFilter, url, busy, formatFilter } = useSelector((state: any) => state.report);

  useEffect(() => {
    if (!selectedPublisher) return;
    dispatch<any>(
      apiReportUrlProvider({
        publisher: selectedPublisher,
        params: {
          dashboardId: 67,
          organisation: selectedPublisher,
          format: formatFilter,
          exclude_status: 'out of scope',
          match_by_path: playlistFilter,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPublisher, playlistFilter, formatFilter]);

  return (
    <React.Fragment>
      <ToolBarGapAnalysisReport
        setPublisher={setPublisher}
        publishers={publishers}
        selectedPublisher={selectedPublisher}
        playlists={playlists}
      />
      {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success" />}
      <ReportFrame src={url}></ReportFrame>
    </React.Fragment>
  );
}
