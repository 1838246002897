import { useDispatch } from 'react-redux';
import { ButtonGroup, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { PublicationSelector } from './PublicationSelector';
import { Heading } from './common/Heading';
import { useEffect } from 'react';

function ToolBarPlaylist({ setPublisher, publishers, selectedPublisher, playlists }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_FILTER_FORMAT', payload: 'vertical' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch({ type: 'SET_FILTER_FORMAT', payload: event.target.value });
  };

  return (
    <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px' }}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
              <Heading>Playlist</Heading>
            </Grid>
            <Grid item>
              <ButtonGroup>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                  }}
                >
                  <Select
                    id="playlist-select"
                    defaultValue="vertical"
                    onChange={handleChange}
                    sx={{
                      fontFamily: 'ProximaNovaBold',
                      textTransform: 'none',
                      color: '#000',
                      height: '43px',
                      width: '200px',
                      marginRight: '30px',
                    }}
                  >
                    {['vertical', 'horizontal'].map((format: string) => (
                      <MenuItem value={format}>{format}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <PublicationSelector
            selectedPublication={selectedPublisher}
            publishers={publishers}
            onChange={setPublisher}
          />
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default styled(ToolBarPlaylist)`
  margin-bottom: 40px;
`;
